
import { ref, defineComponent } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Time from '@/components/Time.vue'
import Line from '@/components/Line.vue'
import { useRouter } from 'vue-router'
import { readDetail, NewsSchema } from '@/modules/community/news/news.api'
import * as R from 'ramda'
import Pic from 'common/Pic.vue'

export default defineComponent({
  name: 'Detail',
  components: { Pic, Line, PageWithHeader, Time },
  props: {
    id: String,
  },
  setup (props) {
    const detail = ref<NewsSchema>({} as NewsSchema)
    const products = ref<string[]>([])

    const fail = () => {
      useRouter().back()
    }

    const updateNews = (resp: NewsSchema) => {
      detail.value = resp
      const arr = resp?.product?.split(',')
      products.value = R.filter(v => Boolean(v), arr)
    }

    if (props.id) {
      readDetail({ articleId: props.id }).then(updateNews).catch(fail)
    }

    return {
      detail,
      products,
    }
  },
})
