import requestResult from '@/common/request/request'

export enum ArticleType {
  ADVICE = 1,
  NORMAL,
}

export type NewsSchema = {
  type: ArticleType.NORMAL;
  articleId: number;
  articleTitle: string;
  articleUrl: string;
  authorName: string;
  authorUrl: string;
  createTime: number;
  bannerUrl: string;
  product: string;
  top: number;
  commentNumber: number;
}

export const readNews = requestResult<Array<NewsSchema>>('/api/article/list')

export const readDetail = requestResult<NewsSchema>('/api/article/detail')
